/* App.styles.css */

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.App-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.App-button {
  background-color: #61dafb;
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-button:hover {
  background-color: #47a3da;
}
